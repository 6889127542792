import { initMobileNavigation } from './initMobileNavigation';

export const initHamburger = () => {
    const hamburger = document.querySelector('[data-hamburger]');

    hamburger?.addEventListener('click', function () {
        this.classList.toggle('hamburger--active');
        initMobileNavigation();
    });
};
