export const initScrollToTop = () => {
    const button = document.querySelector('[data-button-scroll-top]');
    const classShowButton = 'is-show';

    button?.addEventListener('click', handleOnClickButton, false);

    window.addEventListener('scroll', toggleOnScroll, false);

    function handleOnClickButton() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    toggleOnScroll();

    function toggleOnScroll() {
        window.pageYOffset > 200 ? button?.classList.add(classShowButton) : button?.classList.remove(classShowButton);
    }
};
